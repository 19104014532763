const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "calc(100% - 35px)",
    position: "absolute",
    left: "0",
    width: "300px",
    boxShadow: "0px 0px 2px black"
  },
  titleInput: {
    height: "35px",
    boxSizing: "border-box",
    border: "none",
    padding: "5px",
    fontSize: "20px",
    width: "100%",
    backgroundColor: "#29486d",
    color: "white",
    paddingLeft: "50px"
  },
  editIcon: {
    position: "absolute",
    left: "310px",
    top: "6px",
    color: "white",
    width: "10",
    height: "10",
    display: "none"
  },
  editorContainer: {
    height: "100%",
    boxSizing: "border-box"
  },
  menuIcon: {
    position: "absolute",
    zIndex: "99",
    left: "5px",
    top: "6px",
    color: "white",
    width: "10",
    height: "10"
  }
});

export default styles;

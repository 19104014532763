import React from "react";
import SidebarComponent from "./sidebar/sidebar";
import EditorComponent from "./editor/editor";
import { autoId } from "./helpers";
import "./App.css";

const firebase = require("firebase");

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedNoteId: null,
      notes: []
    };
  }

  render() {
    return (
      <div className="app-container">
        <SidebarComponent
          selectedNoteId={this.state.selectedNoteId}
          notes={this.state.notes}
          deleteNote={this.deleteNote}
          selectNote={this.selectNote}
          newNote={this.newNote}
        />
        <EditorComponent
          selectedNoteId={this.state.selectedNoteId}
          note={this.state.notes.find(e => e.id === this.state.selectedNoteId)}
          noteUpdate={this.noteUpdate}
        />
      </div>
    );
  }

  componentDidMount = () => {
    firebase
      .firestore()
      .collection("notes")
      .get()
      .then(snapshot => {
        const notes = [];
        snapshot.forEach(doc => {
          const data = doc.data();
          data["id"] = doc.id;
          notes.push(data);
        });
        this.setState({ notes: notes, selectedNoteId: notes[0].id });
      })
      .catch(err => {
        console.log("Error getting documents", err);
      });
  };

  selectNote = id => {
    this.setState({ selectedNoteId: id });
  };

  noteUpdate = async note => {
    const i = this.state.notes.findIndex(e => e.id === note.id);
    const notes = [...this.state.notes];
    notes[i] = note;
    await this.setState({ notes: notes });

    firebase
      .firestore()
      .collection("notes")
      .doc(note.id)
      .update({
        title: note.title,
        body: note.body,
        clientTime: Date.now(),
        serverTime: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => console.log("Document updated!"))
      .catch(err => console.error("Error updating document: ", err));
  };

  newNote = async title => {
    const id = autoId();
    const note = {
      title: title,
      body: "",
      id: id,
      clientTime: Date.now(),
      serverTime: null
    };
    await this.setState({ notes: [...this.state.notes, note] });
    this.setState({
      selectedNote: this.state.notes.find(e => e.id === id),
      selectedNoteId: id
    });

    firebase
      .firestore()
      .collection("notes")
      .doc(id)
      .set({
        title: note.title,
        body: note.body,
        clientTime: note.clientTime,
        serverTime: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => console.log("Document added!"))
      .catch(err => console.error("Error adding document: ", err));
  };

  deleteNote = async id => {
    await this.setState({
      notes: this.state.notes.filter(_note => _note.id !== id)
    });
    if (this.state.selectedNoteId === id) {
      this.setState({ selectedNoteId: null });
    }

    firebase
      .firestore()
      .collection("notes")
      .doc(id)
      .delete()
      .then(() => console.log("Document deleted!"))
      .catch(err => console.error("Error deleting document: ", err));
  };
}

export default App;

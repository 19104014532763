import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

const firebase = require("firebase");
require("firebase/firestore");

firebase.initializeApp({
  apiKey: "AIzaSyBo21GxnGtt0sYnvIh-sswfjsyMlhZSHSI",
  authDomain: "notebook-971bb.firebaseapp.com",
  databaseURL: "https://notebook-971bb.firebaseio.com",
  projectId: "notebook-971bb",
  storageBucket: "",
  messagingSenderId: "968532241978",
  appId: "1:968532241978:web:af9c0455c7f3c0bc"
});

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase
  .firestore()
  .enablePersistence()
  .catch(function(err) {
    if (err.code === "failed-precondition") {
      console.error(err.code);
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      console.error(err.code);
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

ReactDOM.render(<App />, document.getElementById("evernote-container"));

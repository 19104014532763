import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import List from "@material-ui/core/List";
import { Divider, Button } from "@material-ui/core";
import SidebarItemComponent from "../sidebaritem/sidebarItem";

class SidebarComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      addingNote: false,
      title: null
    };
  }

  render() {
    const { notes, classes, selectedNoteId } = this.props;
    if (notes) {
      return (
        <div className={classes.sidebarContainer} id="sidebarContainer">
          <Button onClick={this.newNoteBtnClick} className={classes.newNoteBtn}>
            {this.state.addingNote ? "Cancel" : "New Note"}
          </Button>
          {this.state.addingNote ? (
            <div>
              <input
                type="text"
                className={classes.newNoteInput}
                placeholder="Enter note title"
                onKeyDown={e =>
                  e.key === "Enter"
                    ? this.newNote()
                    : this.updateTitle(e.target.value)
                }
              />
              <Button
                className={classes.newNoteSubmitBtn}
                onClick={this.newNote}
              >
                Submit Note
              </Button>
            </div>
          ) : null}

          <List style={{ paddingTop: "0px" }}>
            {notes.map(note => {
              return (
                <div key={note.id}>
                  <SidebarItemComponent
                    note={note}
                    selectedNoteId={selectedNoteId}
                    selectNote={this.selectNote}
                    deleteNote={this.deleteNote}
                  />
                  <Divider />
                </div>
              );
            })}
          </List>
        </div>
      );
    } else {
      return <div />;
    }
  }

  newNoteBtnClick = () => {
    this.setState({ title: null, addingNote: !this.state.addingNote });
  };
  updateTitle = title => {
    this.setState({ title: title });
  };
  newNote = () => {
    this.props.newNote(this.state.title);
    this.setState({ title: null, addingNote: false });
  };
  selectNote = id => {
    this.props.selectNote(id);

    let element = document.getElementById("sidebarContainer");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };
  deleteNote = id => this.props.deleteNote(id);
}

export default withStyles(styles)(SidebarComponent);

import React from "react";
import ReactQuill from "react-quill";
import debounce from "../helpers";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class EditorComponent extends React.Component {
  constructor() {
    super();
    this.state = { title: "", body: "", id: null };
  }

  componentDidUpdate() {
    const { note, selectedNoteId } = this.props;
    if (selectedNoteId !== null && selectedNoteId !== this.state.id) {
      this.setState({
        title: note.title,
        body: note.body,
        id: note.id
      });
    }
  }

  render() {
    const { classes, selectedNoteId } = this.props;

    if (selectedNoteId !== null) {
      return (
        <div className={classes.editorContainer}>
          <BorderColorIcon className={classes.editIcon} />
          <input
            className={classes.titleInput}
            placeholder="Note title..."
            value={this.state.title}
            onChange={e => this.updateTitle(e.target.value)}
          />
          <ReactQuill value={this.state.body} onChange={this.updateBody} />
          <div onClick={this.menuBlock}>
            <MenuIcon className={classes.menuIcon} />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  menuBlock = () => {
    let element = document.getElementById("sidebarContainer");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  updateBody = async body => {
    await this.setState({ body: body });
    this.update();
  };

  updateTitle = async title => {
    await this.setState({ title: title });
    this.update();
  };

  update = debounce(() => {
    this.props.noteUpdate({
      title: this.state.title,
      body: this.state.body,
      id: this.state.id
    });
  }, 2000);
}

export default withStyles(styles)(EditorComponent);

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import { removeHTMLTags } from "../helpers";

class SidebarItemComponent extends React.Component {
  render() {
    const { note, classes, selectedNoteId } = this.props;

    return (
      <div key={note.id} onClick={() => this.selectNote(note.id)}>
        <ListItem
          className={classes.listItem}
          selected={selectedNoteId === note.id}
          alignItems="flex-start"
        >
          <div className={classes.textSection}>
            <ListItemText
              primary={note.title}
              secondary={removeHTMLTags(note.body.substring(0, 30)) + "..."}
            />
          </div>
          <DeleteIcon
            onClick={event => this.deleteNote(note, event)}
            className={classes.deleteIcon}
          />
        </ListItem>
      </div>
    );
  }
  selectNote = id => this.props.selectNote(id);
  deleteNote = (note, event) => {
    event.stopPropagation();
    if (window.confirm(`Delete: ${note.title} ?`)) {
      this.props.deleteNote(note.id);
    }
  };
}

export default withStyles(styles)(SidebarItemComponent);
